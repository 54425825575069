import React from "react";

import { Seo } from "../Layout/Seo";
import { Header } from "./Header";
import { RemesasAndRecargas } from "./RemesasAndRecargas";
import { Whatis } from "./Whatis";
import { Block2 } from "./Block_2";
import { StepByStep } from "../StepByStep";
import { Info } from "./Info";
import { AboutTeam } from "./AboutTeam";
import { Posts } from "../Posts";
import { CallToAction } from "./CallToAction";

const Home = ({ pageContext, location, lang }) => {
  const { newHome, language } = pageContext;
  const fragments = newHome;

  return (
    <Seo
      title={fragments.SeoTitle}
      description={fragments.SeoDescription}
      lang={lang}
    >
      <Header data={fragments.newHeaderHomeNew} lang={language} />
      <RemesasAndRecargas data={fragments.enviosAndRemesas} lang={language} />
      <Whatis data={fragments.whatis} lang={language} />
      <Block2 data={fragments.Block2} />
      <StepByStep data={fragments.Block3} type={true} />
      <Info data={fragments.Info} />
      <AboutTeam data={fragments.AboutTeam} />
      <Posts location={location} lang={lang} />
      {/* <CallToAction data={fragments.CallToAction} isHome /> */}
    </Seo>
  );
};

export default Home;
